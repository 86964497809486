import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import Header from 'components/HeaderSection'
import SplitScreenSection from 'components/SplitScreenSection'
import OverlayLeftSection from 'components/OverlayLeftSection'
import MarkdownOverlay from 'components/MarkdownOverlaySection'
import AdditionalResources from './AdditionalResources'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const {
    seo,
    header,
    benefitsSection,
    focusSection,
    languageModelSection,
    resourcesSection,
    faqSection,
  } = frontmatter
  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <Header
        title={header.title}
        src={header.image.childImageSharp.gatsbyImageData.images.fallback.src}
        content={header.description}
        color="lightBlue"
      />
      <SplitScreenSection
        title={benefitsSection.title}
        src={
          benefitsSection.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
        content={benefitsSection.description}
        color="green"
      />
      <OverlayLeftSection
        title={focusSection.title}
        src={
          focusSection.image.childImageSharp.gatsbyImageData.images.fallback.src
        }
        content={focusSection.description}
        color="purple"
      />
      <MarkdownOverlay
        src={
          languageModelSection.image.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
        content={languageModelSection.description}
        color="lightBlue"
      />
      <AdditionalResources
        src={
          resourcesSection.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
        title={resourcesSection.title}
        resources={resourcesSection.resources}
        color="lightBlue"
      />
      <MarkdownOverlay
        src={
          faqSection.image.childImageSharp.gatsbyImageData.images.fallback.src
        }
        content={faqSection.description}
        color="purple"
      />
    </Fragment>
  )
}

export const query = graphql`
  query DualImmersionPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        benefitsSection {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        focusSection {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        languageModelSection {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          description
        }
        resourcesSection {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          resources {
            text
            link
          }
        }
        faqSection {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          description
        }
      }
    }
  }
`

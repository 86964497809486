import React from 'react'
import styled from 'styled-components'

import SplitScreen from 'components/SplitScreen'
import Heading from 'components/Heading'
import MD from 'components/styled/Markdown'

const Markdown = styled(MD)`
  & > p {
    color: white;
  }

  & > strong {
    color: white;
  }
`

export default ({ color, title, content, src }) => (
  <SplitScreen src={src} color={color}>
    <Heading>{title}</Heading>
    <Markdown color={color} children={content} />
  </SplitScreen>
)

import React from 'react'
import styled from 'styled-components'
import Media from 'react-media'

export default ({ children, src, mobileImage, className }) => (
  <Container className={className}>
    <Card>{children}</Card>
    {mobileImage === 'hide' ? (
      <Media query="(max-width: 950px)">
        {(matches) => (matches ? null : <Image image={src} />)}
      </Media>
    ) : (
      <Image image={src} />
    )}
  </Container>
)

const Container = styled.div`
  margin: 100px 0;
  height: auto;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    margin: 0;
    padding: 0;
    width: 100%;
    flex-direction: column-reverse;
  }
`
const Card = styled.div`
  padding: 50px 85px 40px;
  margin: 60px -5% 80px 0;
  width: 55%;
  z-index: 100;
  background-color: white;

  @media (min-width: ${(props) => props.theme.phone}px) {
    box-shadow: ${(props) => props.theme.boxShadow};
  }

  @media (max-width: ${(props) => props.theme.desktop}px) {
    margin: 0;
    width: 100%;
  }

  @media (max-width: 587px) {
    padding: 60px 10px 65px;
  }
`
const Image = styled.div`
  margin: 0;
  padding: 0;
  width: 35%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    margin: 0;
    height: 300px;
    width: 100%;
    display: block;
  }
`

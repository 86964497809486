import React from 'react'
import styled from 'styled-components'

import SplitScreen from 'components/SplitScreen'
import Heading from 'components/Heading'
import Paragraph from 'components/styled/Paragraph'

export default ({ src, title, color, resources }) => (
  <SplitScreen color={color} src={src}>
    <Heading>{title}</Heading>
    <Paragraph>
      {resources.map((resource, i) => (
        <Link key={i} href={resource.link}>
          {resource.text}
        </Link>
      ))}
    </Paragraph>
  </SplitScreen>
)

const Link = styled.a`
  display: inline-block;
  margin: 10px 10px;
  padding: 10px 20px;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 12pt;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid white;
  color: white;
  background: transparent;
  transition: 0.5s;

  &:hover {
    color: ${(props) => props.theme.lightBlue};
    background: white;
    box-shadow: ${(props) => props.theme.boxShadow};
  }
`
